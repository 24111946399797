/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
.content {
  margin-left: 0;
  min-height: 100vh;
  transition: all 1.5s;
}

@media only screen and (max-width: 768px) {
  .content {
    width: calc(100vw - 100px);
  }

  .sidebar-noshow {
    margin-left: 0 !important;
    width: 100vw !important;
  }
}

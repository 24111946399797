/* Estilização do container */
.agenda-container {
  background-color: #eaebec;
  border-radius: 8px;
  min-height: calc(100vh - 100px);
  padding: 20px;
  display: flex;
  flex-direction: column;
}

/* Ajuste de altura para o modo mês */
.fc .fc-dayGridMonth-view {
  height: calc(50vh - 100px) !important;
  max-height: calc(50vh - 100px) !important;
}

/* Ajuste das células no modo mês */
.fc-dayGridMonth-view .fc-daygrid-day {
  height: auto !important;
}

.fc-dayGridMonth-view .fc-daygrid-day-frame {
  height: 100% !important;
  min-height: 0 !important;
}

.fc-dayGridMonth-view .fc-daygrid-day-events {
  min-height: 0 !important;
  margin: 0 !important;
}

.fc-view-harness.fc-view-harness-active {
  height: auto !important;
}

.fc-daygrid-day-frame {
  border: 1px solid #e0e0e0 !important;
}

.fc-daygrid-day-top {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.fc-daygrid-day-events {
  display: flex !important;
  padding: 0.1rem !important;
}

/* Ajuste de altura para os modos semana e dia */
.fc-timeGridWeek-view .fc-view-harness,
.fc-timeGridDay-view .fc-view-harness {
  height: calc(100vh - 200px) !important;
}

.btn-add-agenda {
  position: fixed;
  bottom: 20px;
  right: 20px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1) !important;
  background-color: #fff !important;
  color: #1a73e8 !important;
  border-radius: 20px !important;
  width: 40px !important;
  height: 40px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000 !important;
}

/* Estilização dos botões */
.fc-button {
  background-color: transparent !important;
  border: none !important;
  color: #1a73e8 !important;
  border-radius: 20px !important;
  padding: 8px 16px !important;
  font-weight: 500 !important;
  box-shadow: none !important;
}

.fc-button:hover {
  background-color: rgba(0, 0, 0, 0.04) !important;
}

.fc-button-active {
  background-color: #e8f0fe !important;
}

/* Estilização do cabeçalho */
.fc .fc-toolbar {
  display: flex !important;
  flex-direction: column !important;
}

.fc-toolbar-title {
  font-size: 1.375rem !important;
  font-weight: 400 !important;
  color: #3c4043 !important;
  text-transform: capitalize !important;
}

/* Estilização dos dias da semana */
.fc-col-header-cell {
  background-color: #eaebec !important;
  padding: 8px 0 !important;
  font-weight: 400 !important;
  color: #70757a;
  text-transform: uppercase;
  font-size: 0.8em !important;
}

/* Estilização das células dos dias */
.fc-daygrid-day {
  cursor: pointer;
  height: 100px !important;
  background-color: #eaebec !important;
}

.fc-daygrid-day-number {
  color: #202124;
  font-size: 0.75em;
  padding: 0.3rem !important;
}

/* Estilização para dias de outros meses */
.fc-day-other .fc-daygrid-day-number {
  color: #70757a;
  opacity: 0.6;
}

/* Estilização do dia atual */
.fc-day-today {
  background-color: transparent !important;
}

.fc-day-today .fc-daygrid-day-number {
  background-color: #eaebec;
  /* color: #1a73e8; */
  font-weight: 900;
  text-decoration: underline;
}

/* Estilização do dia selecionado */
.fc-day-selected .fc-daygrid-day-number {
  background-color: #1a73e8 !important;
  color: white !important;
  border-radius: 50% !important;
  width: 32px !important;
  height: 32px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

/* Garante que o estilo do dia selecionado tenha prioridade */
.fc-day-selected.fc-day-today .fc-daygrid-day-number {
  background-color: #1a73e8 !important;
  color: white !important;
  text-decoration: none !important;
  font-weight: normal !important;
}

/* Estilização dos eventos */
.fc-event {
  background-color: #eaebec !important;
  border: none !important;
  padding: 0px 0px 0px 0px !important;
  border-radius: 4px !important;
  font-size: 0.85em !important;
}

.fc-event-main {
  border: none !important;
  padding: 0px 0px 0px 0px !important;
}

/* Estilização da borda do calendário */
.fc {
  border: none !important;
  background-color: #eaebec !important;
  flex: 1;
}

.fc td,
.fc th {
  background-color: #eaebec !important;
}

/* Estilização dos botões de navegação */
.fc-prev-button,
.fc-next-button {
  color: #70757a !important;
  font-size: 1.2em !important;
  width: 32px !important;
  height: 32px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 0 !important;
}

.fc-day-header {
  background-color: #eaebec !important;
}

/* Estilização para domingos */
.fc-day-sun .fc-daygrid-day-number {
  color: #d93025 !important;
}

/* Mantém a cor vermelha mesmo para domingos de outros meses */
.fc-day-sun.fc-day-other .fc-daygrid-day-number {
  color: #d93025 !important;
  opacity: 0.6;
}

/* Estilização do header */
.fc-header-toolbar {
  padding: 16px !important;
  margin-bottom: 0 !important;
}

/* Container dos botões de visualização */
.fc-button-group {
  display: flex !important;
  gap: 24px !important;
  background: #eaebec !important;
  padding: 4px !important;
  border-radius: 8px !important;
}

/* Estilização dos botões de visualização */
.fc-button-group .fc-button {
  background: transparent !important;
  border: none !important;
  color: #70757a !important;
  text-transform: none !important;
  padding: 8px 16px !important;
  font-size: 0.875rem !important;
  font-weight: normal !important;
}

.fc-button-group .fc-button.fc-button-active {
  color: #1a73e8 !important;
  font-weight: 500 !important;
  background: transparent !important;
}

/* Estilização do header da semana */
.fc-timeGridWeek-view .fc-col-header-cell {
  text-align: center !important;
  padding: 4px 0 !important;
}

.fc-timeGridWeek-view .fc-col-header-cell-cushion {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  padding: 8px 0 !important;
  color: #70757a !important;
  font-weight: normal !important;
}

/* Estilo para o dia da semana */
.fc-timeGridWeek-view .fc-col-header-cell-cushion:first-line {
  font-size: 11px !important;
  text-transform: uppercase !important;
  margin-bottom: 4px !important;
}

/* Estilo para o número do dia */
.fc-timeGridWeek-view .fc-col-header-cell-cushion::after {
  content: attr(data-date);
  font-size: 26px !important;
  line-height: 32px !important;
  color: #3c4043 !important;
}

/* Estilo para o dia atual na visualização semanal */
.fc-timeGridWeek-view .fc-day-today .fc-col-header-cell-cushion {
  background-color: #1a73e8 !important;
  color: white !important;
  border-radius: 50% !important;
  width: 32px !important;
  height: 32px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin: 0 auto !important;
}

/* Mantém o background-color consistente */
.fc-timeGridWeek-view .fc-col-header,
.fc-timeGridWeek-view .fc-col-header-cell {
  background-color: #eaebec !important;
}

/* Estilo para adicionar o dia da semana */
.fc-timeGridWeek-view .fc-col-header-cell:nth-child(2):before {
  content: "DOM" !important;
}

.fc-timeGridWeek-view .fc-col-header-cell:nth-child(3):before {
  content: "SEG" !important;
}

.fc-timeGridWeek-view .fc-col-header-cell:nth-child(4):before {
  content: "TER" !important;
}

.fc-timeGridWeek-view .fc-col-header-cell:nth-child(5):before {
  content: "QUA" !important;
}

.fc-timeGridWeek-view .fc-col-header-cell:nth-child(6):before {
  content: "QUI" !important;
}

.fc-timeGridWeek-view .fc-col-header-cell:nth-child(7):before {
  content: "SEX" !important;
}

.fc-timeGridWeek-view .fc-col-header-cell:nth-child(8):before {
  content: "SÁB" !important;
}

/* Estilo comum para todos os :before */
.fc-timeGridWeek-view .fc-col-header-cell-cushion:before {
  display: block !important;
  font-size: 11px !important;
  color: #70757a !important;
  margin-bottom: 4px !important;
  font-weight: 500 !important;
}

/* Ajuste para o dia atual manter o texto visível */
.fc-timeGridWeek-view .fc-day-today .fc-col-header-cell-cushion:before {
  color: #1a73e8 !important;
}

/* Estilização das bordas no modo mês */
.fc-dayGridMonth-view td,
.fc-dayGridMonth-view th {
  border-color: #eaebec !important;
}

/* Estilização das bordas no modo semana */
.fc-timeGridWeek-view .fc-scrollgrid,
.fc-timeGridWeek-view .fc-scrollgrid-section,
.fc-timeGridWeek-view .fc-timegrid,
.fc-timeGridWeek-view .fc-timegrid-slot,
.fc-timeGridWeek-view td,
.fc-timeGridWeek-view th,
.fc-timeGridWeek-view .fc-timegrid-axis,
.fc-timeGridWeek-view .fc-timegrid-cols,
.fc-timeGridWeek-view .fc-timegrid-col {
  border-color: #e0e0e0 !important;
}

/* Estilização das bordas no modo dia */
.fc-timeGridDay-view .fc-scrollgrid,
.fc-timeGridDay-view .fc-scrollgrid-section,
.fc-timeGridDay-view .fc-timegrid,
.fc-timeGridDay-view .fc-timegrid-slot,
.fc-timeGridDay-view td,
.fc-timeGridDay-view th,
.fc-timeGridDay-view .fc-timegrid-axis,
.fc-timeGridDay-view .fc-timegrid-cols,
.fc-timeGridDay-view .fc-timegrid-col {
  border-color: #e0e0e0 !important;
}

/* Sobrescreve a borda da tabela em todos os modos */
.fc-theme-standard .fc-scrollgrid {
  border-color: #e0e0e0 !important;
}

/* Ajuste específico para as células de tempo */
.fc-timegrid-slot {
  border-color: #e0e0e0 !important;
}

/* Mantém a altura original no modo mês */
.fc-dayGridMonth-view {
  height: auto !important;
}

.event-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 4px;
  color: #fff;
}

.event-content-month {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
}

.event-time {
  font-size: 0.8em;
  margin-bottom: 2px;
}

.event-title-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  font-size: 0.9em;
}

.events-of-selected-date {
  margin-top: 20px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: calc(50vh - 200px);
  margin-top: 20px;
  overflow-y: auto;
  overflow-x: hidden;
}

.events-of-selected-date .event-color-indicator {
  width: 20px;
  height: 20px;
  border-radius: 10px;
}

.events-of-selected-date h4 {
  color: #3c4043;
  margin-bottom: 16px;
  font-size: 1.1rem;
  font-weight: 500;
}

.selected-date-event {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px;
  border-bottom: 1px solid #e0e0e0;
  width: 100%;
  overflow: hidden;
}

.selected-date-event:last-child {
  border-bottom: none;
}

.selected-date-event .event-time {
  min-width: 60px;
  color: #70757a;
  font-size: 0.9rem;
  margin-right: 12px;
}

.selected-date-event .event-title {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #3c4043;
  font-size: 0.95rem;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.fc-daygrid-day-number.fc-day-selected {
  background-color: #1a73e8 !important;
  color: white !important;
  border-radius: 50% !important;
  width: 20px !important;
  height: 20px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 0 !important;
  margin: 4px !important;
}

.exclamation-icon {
  font-size: 0.8em !important;
  color: var(--yellow) !important;
}

.reagendamento-icon {
  color: var(--dark) !important;
}

.event-content-month .exclamation-icon {
  position: absolute;
  font-size: 0.75em !important;
  top: -5px;
  right: -5px;
}

.event-content-month .reagendamento-icon {
  position: absolute;
  font-size: 0.75em !important;
  top: -5px;
  right: -5px;
}

.event-content .exclamation-icon {
  position: absolute;
  font-size: 1rem !important;
  top: -5px;
  right: -5px;
}

.event-content .reagendamento-icon {
  position: absolute;
  font-size: 1rem !important;
  top: -3px;
  right: -3px;
}

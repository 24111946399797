/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
.sidebar {
  min-width: 100px;
  width: 100px;
  background: var(--danger);
  color: var(--white);
  transition: all 1.5s;
  -webkit-transition: all 1.5s;
}

.sidebar-backdrop {
  display: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: var(--dark);
  opacity: 0.8;
  z-index: 998;
}

.sidebar.is-open {
  min-width: 320px !important;
  width: 320px !important;
  margin-left: 0;
  transition: 1.5s;
  -webkit-transition: 1.5s;
}

.sidebar.is-open.no-show {
  margin-left: -320px;
  transition: 1.5s;
  -webkit-transition: 1.5s;
}

.sidebar.no-show {
  margin-left: -100px;
  transition: 1.5s;
  -webkit-transition: 1.5s;
}

.sidebar-header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: var(--danger);
}

.sidebar-header img {
  width: 100%;
  max-width: 150px;
  transition: 1.5s;
  -webkit-transition: 1.5s;
}

.sidebar-header h3 {
  color: var(--white);
  padding: 1em;
}

.sidebar ul p {
  color: var(--white);
  padding: 10px;
}

.sidebar ul {
  position: absolute;
  background-color: var(--white);
  padding-bottom: 0 !important;
}

.menu-open {
  background: var(--info);
}

.nav-item:hover {
  background: var(--white);
}

li a.dropdown-toggle::after {
  display: inline-flex;
  position: relative;
  left: 60%;
  top: 10%;
}

.side-menu {
  min-height: calc(100vh - 110px);
  overflow-y: auto;
}

.side-menu .items {
  position: relative;
  height: 130px;
  background: var(--danger);
}

.side-menu a {
  position: absolute;
  width: 320px;
  padding: 0 1.5rem;
  color: var(--dark);
  transition: width 1.5s;
}

.side-menu a.is-closed {
  width: 100px;
}

.side-menu a:hover {
  color: var(--primary);
}

.side-menu li:hover a {
  color: var(--primary);
}

/*
.side-menu li.active {
  background-color: var(--white);
} */

.side-menu::-webkit-scrollbar {
  width: 5px;
}

.side-menu::-webkit-scrollbar-thumb {
  background: var(--primary);
  border-radius: 3px;
}

.side-menu::-webkit-scrollbar-thumb:hover {
  background: var(--danger);
}

.side-menu .menu-item .menu-item-icon {
  position: absolute;
  top: 30px;
  left: -12px;
  z-index: 100;
  border-radius: 10px;
  transition: left 1.5s;
  -webkit-transition: left 1.5s;
}

.side-menu .menu-item .menu-item-icon.is-closed {
  left: 5px;
}

.side-menu .menu-item .card {
  width: 100%;
  height: 100px;
  overflow: hidden;
  border-radius: 15px;
  z-index: 50;
  visibility: visible;
  opacity: 1;
  transition: opacity 0.5s linear, width 1.5s;
  -webkit-transition: opacity 0.5s linear, width 1.5s;
}

.side-menu .menu-item .card.is-closed {
  visibility: hidden;
  opacity: 0;
  width: 0 !important;
  transition: visibility 0s 0.5s, opacity 0.5s linear, width 1.5s;
  -webkit-transition: visibility 0s 0.5s, opacity 0.5s linear, width 1.5s;
}

.side-menu .menu-item .card * {
  visibility: visible;
  opacity: 1;
  transition: opacity 2s linear;
  -webkit-transition: opacity 2s linear;
}

.side-menu .menu-item .card.is-closed * {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.5s, opacity 0.5s linear;
  -webkit-transition: visibility 0s 0.5s, opacity 0.5s linear;
}

.side-menu .menu-item .menu-item-goto-button {
  position: absolute;
  top: 35px;
  right: -5px;
  z-index: 100;
  visibility: visible;
  opacity: 1;
  transition: opacity 2s linear;
  -webkit-transition: opacity 2s linear;
}

.side-menu .menu-item .menu-item-goto-button.is-closed {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 1s, opacity 1s linear;
  -webkit-transition: visibility 0s 1s, opacity 1s linear;
}

@media only screen and (max-width: 768px) {
  .sidebar {
    position: relative;
    z-index: 999;
  }

  .sidebar-backdrop.is-show {
    display: block !important;
  }

  .sidebar.is-open {
    min-width: 100px !important;
    width: 100px !important;
    margin-left: 0;
    transition: all 1.5s, height 0s;
    -webkit-transition: all 1.5s, height 0s;
  }

  .sidebar.is-open.no-show {
    width: 380px !important;
    margin-left: -380px !important;
  }

  .sidebar.no-show {
    margin-left: -100px !important;
  }

  .side-menu a {
    width: 100vw !important;
  }

  .sidebar.is-open.no-show .side-menu a {
    width: 380px !important;
  }

  .side-menu a.is-closed {
    width: 100px !important;
  }
}

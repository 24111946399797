.custom-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border: 0;
}

.custom-table tbody {
  border: 0;
}

.custom-table thead {
  border: 0;
}

.custom-table thead tr {
  border: 0;
}

.custom-table thead th {
  background-color: #76cde8;
  width: 180px;
  max-width: 180px;
  padding: 0.5rem;
  font-size: 0.75rem;
  border-top: 0;
  border-bottom: 0;
}

.custom-table thead th:first-child {
  border-top-left-radius: 1rem;
}

.custom-table thead th:last-child {
  border-top-right-radius: 1rem;
}

.custom-table thead th:not(:first-child):not(:last-child) {
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
}

.custom-table tbody tr {
  cursor: pointer;
}

.custom-table tbody tr:nth-child(odd) {
  background-color: #fff;
}

.custom-table tbody tr:nth-child(even) {
  background-color: #e6e6e6;
}

.custom-table tbody tr:hover {
  background-color: #c1c1c1;
}

.custom-table tbody td {
  padding: 1rem 0.5rem !important;
  font-size: 0.75rem;
}

.custom-table tbody td:not(:first-child):not(:last-child) {
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
}

.custom-table tfoot tr {
  background-color: #c1c1c1;
}

.custom-table tfoot td {
  padding: 1.25rem 0.5rem !important;
  font-size: 0.75rem;
}

.custom-table tfoot td:not(:first-child):not(:last-child) {
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
}

.custom-table tfoot td:first-child {
  border-bottom-left-radius: 1rem;
}

.custom-table tfoot td:last-child {
  border-bottom-right-radius: 1rem;
}
